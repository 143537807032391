import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="162" height="24" viewBox="0 0 162 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.9988 0.00238564L0 6.0012L17.9988 24C21.3116 20.6872 21.3116 15.3152 17.9988 12L5.9988 0V0.00238564Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9971 6.00126L29.9983 0.00244141L19.874 10.1268C22.2764 12.5291 23.3465 15.7633 23.0915 18.9093L35.9971 6.00364V6.00126Z"
        fill="currentColor"
      />
      <path
        d="M48.2809 21.1852L42.1582 4.94531H45.0086L49.7991 18.4277L54.6349 4.94531H57.4615L51.3626 21.1852H48.2786H48.2809Z"
        fill="currentColor"
      />
      <path
        d="M63.9346 21.3952C62.0661 21.3952 60.5455 20.7875 59.3777 19.572C58.2099 18.3565 57.626 16.7454 57.626 14.7362C57.626 12.7271 58.217 11.1184 59.4015 9.91242C60.5861 8.70646 62.1161 8.10107 63.9918 8.10107C65.8675 8.10107 67.3904 8.68261 68.5606 9.84091C69.7285 11.0016 70.3124 12.5174 70.3124 14.3859C70.3124 14.8387 70.2814 15.2582 70.2194 15.6467H60.3596C60.4216 16.7215 60.7839 17.5748 61.4464 18.2063C62.109 18.8379 62.9384 19.1525 63.9346 19.1525C65.3837 19.1525 66.4037 18.5519 66.9948 17.3531H69.8691C69.4806 18.5376 68.7704 19.5076 67.7432 20.2631C66.7159 21.0186 65.4456 21.3952 63.9346 21.3952ZM66.4824 11.29C65.796 10.6584 64.9642 10.3438 63.9823 10.3438C63.0004 10.3438 62.1781 10.6584 61.5179 11.29C60.8554 11.9215 60.4788 12.7652 60.3859 13.8258H67.5358C67.5191 12.7676 67.1688 11.9215 66.4848 11.29H66.4824Z"
        fill="currentColor"
      />
      <path
        d="M74.4263 8.31081V10.5535C75.2509 8.91856 76.5379 8.10107 78.2825 8.10107V10.8586H77.6057C76.5618 10.8586 75.7705 11.1231 75.2343 11.6522C74.698 12.1813 74.4287 13.1013 74.4287 14.4097V21.1855H71.7642V8.31081H74.4287H74.4263Z"
        fill="currentColor"
      />
      <path
        d="M85.8064 10.482H82.6747V17.6081C82.6747 18.0919 82.7867 18.4375 83.0131 18.6472C83.2395 18.8569 83.6233 18.9618 84.169 18.9618H85.804V21.1807H83.7019C81.2256 21.1807 79.9863 19.989 79.9863 17.6057V5.10522H82.6723V8.30603H85.804V10.4796L85.8064 10.482Z"
        fill="currentColor"
      />
      <path
        d="M92.6867 21.3951C90.8087 21.3951 89.2643 20.785 88.0488 19.5599C86.8333 18.3373 86.2256 16.7333 86.2256 14.7456C86.2256 12.7579 86.8524 11.1564 88.106 9.93133C89.3596 8.70869 90.9207 8.09619 92.7916 8.09619C94.6625 8.09619 96.2212 8.70869 97.4772 9.93133C98.7308 11.154 99.3576 12.7556 99.3576 14.7337C99.3576 16.7119 98.7142 18.3158 97.4296 19.548C96.1449 20.7778 94.5624 21.3951 92.6867 21.3951ZM95.4562 17.9846C96.2355 17.2052 96.624 16.1232 96.624 14.7361C96.624 13.349 96.245 12.2717 95.4919 11.4995C94.7364 10.7273 93.8212 10.3436 92.7463 10.3436C91.6715 10.3436 90.7682 10.7297 90.0365 11.4995C89.3048 12.2717 88.9378 13.3538 88.9378 14.748C88.9378 16.1422 89.2953 17.2243 90.0127 17.9965C90.73 18.7687 91.6214 19.1524 92.6891 19.1524C93.7569 19.1524 94.6792 18.7639 95.4585 17.9846H95.4562Z"
        fill="currentColor"
      />
      <path d="M99.9873 7.02353V4.94287H111.32V7.02353H106.997V21.1828H104.332V7.02353H99.9873Z" fill="currentColor" />
      <path
        d="M115.503 8.31081V10.5535C116.328 8.91856 117.615 8.10107 119.359 8.10107V10.8586H118.682C117.638 10.8586 116.847 11.1231 116.311 11.6522C115.775 12.1813 115.505 13.1013 115.505 14.4097V21.1855H112.841V8.31081H115.505H115.503Z"
        fill="currentColor"
      />
      <path
        d="M121.507 19.5147C120.339 18.2611 119.755 16.6571 119.755 14.7004C119.755 12.7437 120.334 11.1564 121.495 9.93377C122.655 8.71113 124.1 8.09863 125.83 8.09863C126.843 8.09863 127.734 8.32979 128.506 8.78739C129.279 9.24737 129.874 9.85037 130.294 10.5987V8.30837H132.98V21.183H130.294V18.7759C129.858 19.5695 129.257 20.2059 128.494 20.6801C127.732 21.1544 126.843 21.3927 125.83 21.3927C124.116 21.3927 122.677 20.7659 121.507 19.5123V19.5147ZM129.136 17.9036C129.908 17.1171 130.292 16.0613 130.292 14.7385C130.292 13.4158 129.905 12.3624 129.136 11.5854C128.363 10.8061 127.448 10.4176 126.39 10.4176C125.332 10.4176 124.417 10.7989 123.644 11.5616C122.872 12.3242 122.489 13.3681 122.489 14.6932C122.489 16.0184 122.875 17.0813 123.644 17.8821C124.417 18.6853 125.329 19.0857 126.39 19.0857C127.451 19.0857 128.363 18.6924 129.136 17.906V17.9036Z"
        fill="currentColor"
      />
      <path
        d="M136.18 19.5145C135.013 18.2608 134.429 16.6569 134.429 14.7002C134.429 12.7435 135.008 11.1562 136.169 9.93354C137.329 8.7109 138.773 8.0984 140.504 8.0984C141.517 8.0984 142.408 8.32956 143.18 8.78715C143.952 9.24713 144.548 9.85014 144.968 10.5985V3.89185H147.654V21.1828H144.968V18.7756C144.532 19.5693 143.931 20.2056 143.168 20.6799C142.406 21.1542 141.517 21.3925 140.504 21.3925C138.79 21.3925 137.351 20.7657 136.18 19.5121V19.5145ZM143.809 17.9033C144.582 17.1168 144.965 16.0611 144.965 14.7383C144.965 13.4156 144.579 12.3622 143.809 11.5852C143.037 10.8058 142.122 10.4174 141.064 10.4174C140.006 10.4174 139.09 10.7987 138.318 11.5613C137.546 12.324 137.162 13.3679 137.162 14.693C137.162 16.0181 137.548 17.0811 138.318 17.8819C139.09 18.6851 140.003 19.0855 141.064 19.0855C142.124 19.0855 143.037 18.6922 143.809 17.9057V17.9033Z"
        fill="currentColor"
      />
      <path
        d="M155.411 21.3952C153.543 21.3952 152.022 20.7875 150.854 19.572C149.686 18.3565 149.103 16.7454 149.103 14.7362C149.103 12.7271 149.694 11.1184 150.878 9.91242C152.063 8.70646 153.593 8.10107 155.468 8.10107C157.344 8.10107 158.867 8.68261 160.037 9.84091C161.205 11.0016 161.789 12.5174 161.789 14.3859C161.789 14.8387 161.758 15.2582 161.696 15.6467H151.836C151.898 16.7215 152.26 17.5748 152.923 18.2063C153.586 18.8379 154.415 19.1525 155.411 19.1525C156.86 19.1525 157.88 18.5519 158.471 17.3531H161.346C160.957 18.5376 160.247 19.5076 159.22 20.2631C158.192 21.0186 156.922 21.3952 155.411 21.3952ZM157.959 11.29C157.273 10.6584 156.441 10.3438 155.459 10.3438C154.477 10.3438 153.655 10.6584 152.994 11.29C152.332 11.9215 151.955 12.7652 151.86 13.8258H159.01C158.993 12.7676 158.643 11.9215 157.959 11.29Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default Icon;
