import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="162" height="24" viewBox="0 0 162 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.9988 0.00238564L0 6.0012L17.9988 24C21.3116 20.6872 21.3116 15.3152 17.9988 12L5.9988 0V0.00238564Z"
        fill="#30E8BF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9976 6.00126L29.9988 0.00244141L19.8745 10.1268C22.2769 12.5291 23.347 15.7633 23.092 18.9093L35.9976 6.00364V6.00126Z"
        fill="#30E8BF"
      />
      <path
        d="M48.2809 19.1852L42.1582 2.94531H45.0086L49.7991 16.4277L54.6349 2.94531H57.4615L51.3626 19.1852H48.2786H48.2809Z"
        fill="currentColor"
      />
      <path
        d="M63.9346 19.3951C62.0661 19.3951 60.5455 18.7873 59.3777 17.5718C58.2099 16.3564 57.626 14.7453 57.626 12.7361C57.626 10.727 58.217 9.11825 59.4015 7.91229C60.5861 6.70634 62.1161 6.10095 63.9918 6.10095C65.8675 6.10095 67.3904 6.68249 68.5606 7.84078C69.7285 9.00146 70.3124 10.5173 70.3124 12.3858C70.3124 12.8386 70.2814 13.2581 70.2194 13.6465H60.3596C60.4216 14.7214 60.7839 15.5746 61.4464 16.2062C62.109 16.8378 62.9384 17.1524 63.9346 17.1524C65.3837 17.1524 66.4037 16.5518 66.9948 15.353H69.8691C69.4806 16.5375 68.7704 17.5075 67.7432 18.263C66.7159 19.0185 65.4456 19.3951 63.9346 19.3951ZM66.4824 9.28983C65.796 8.65825 64.9642 8.34366 63.9823 8.34366C63.0004 8.34366 62.1781 8.65825 61.5179 9.28983C60.8554 9.92141 60.4788 10.7651 60.3859 11.8257H67.5358C67.5191 10.7675 67.1688 9.92141 66.4848 9.28983H66.4824Z"
        fill="currentColor"
      />
      <path
        d="M74.4263 6.31069V8.55339C75.2509 6.91844 76.5379 6.10095 78.2825 6.10095V8.85846H77.6057C76.5618 8.85846 75.7705 9.123 75.2343 9.6521C74.698 10.1812 74.4287 11.1012 74.4287 12.4096V19.1854H71.7642V6.31069H74.4287H74.4263Z"
        fill="currentColor"
      />
      <path
        d="M85.8064 8.48188H82.6747V15.608C82.6747 16.0918 82.7867 16.4374 83.0131 16.6471C83.2395 16.8568 83.6233 16.9617 84.169 16.9617H85.804V19.1806H83.7019C81.2256 19.1806 79.9863 17.9889 79.9863 15.6056V3.1051H82.6723V6.30591H85.804V8.47949L85.8064 8.48188Z"
        fill="currentColor"
      />
      <path
        d="M92.6872 19.3951C90.8092 19.3951 89.2648 18.785 88.0493 17.5599C86.8338 16.3373 86.2261 14.7333 86.2261 12.7456C86.2261 10.7579 86.8529 9.15636 88.1065 7.93133C89.3601 6.70869 90.9212 6.09619 92.7921 6.09619C94.663 6.09619 96.2217 6.70869 97.4777 7.93133C98.7313 9.15397 99.3581 10.7556 99.3581 12.7337C99.3581 14.7119 98.7146 16.3158 97.43 17.548C96.1454 18.7778 94.5629 19.3951 92.6872 19.3951ZM95.4566 15.9846C96.236 15.2052 96.6245 14.1232 96.6245 12.7361C96.6245 11.349 96.2455 10.2717 95.4924 9.49954C94.7369 8.72735 93.8217 8.34364 92.7468 8.34364C91.6719 8.34364 90.7687 8.72973 90.037 9.49954C89.3053 10.2717 88.9383 11.3538 88.9383 12.748C88.9383 14.1422 89.2958 15.2243 90.0132 15.9965C90.7305 16.7687 91.6219 17.1524 92.6896 17.1524C93.7573 17.1524 94.6797 16.7639 95.459 15.9846H95.4566Z"
        fill="currentColor"
      />
      <path d="M99.9873 5.02365V2.94299H111.32V5.02365H106.997V19.1829H104.332V5.02365H99.9873Z" fill="currentColor" />
      <path
        d="M115.502 6.31069V8.55339C116.327 6.91844 117.614 6.10095 119.359 6.10095V8.85846H118.682C117.638 8.85846 116.847 9.123 116.31 9.6521C115.774 10.1812 115.505 11.1012 115.505 12.4096V19.1854H112.84V6.31069H115.505H115.502Z"
        fill="currentColor"
      />
      <path
        d="M121.506 17.5146C120.338 16.2609 119.754 14.657 119.754 12.7003C119.754 10.7436 120.334 9.15629 121.494 7.93365C122.655 6.71101 124.099 6.09851 125.829 6.09851C126.842 6.09851 127.734 6.32967 128.506 6.78726C129.278 7.24724 129.874 7.85024 130.293 8.59861V6.30824H132.979V19.1829H130.293V16.7757C129.857 17.5694 129.257 18.2057 128.494 18.68C127.731 19.1543 126.842 19.3926 125.829 19.3926C124.116 19.3926 122.676 18.7658 121.506 17.5122V17.5146ZM129.135 15.9034C129.907 15.1169 130.291 14.0612 130.291 12.7384C130.291 11.4157 129.905 10.3623 129.135 9.5853C128.363 8.80596 127.448 8.41747 126.39 8.41747C125.331 8.41747 124.416 8.79878 123.644 9.56145C122.872 10.3241 122.488 11.368 122.488 12.6931C122.488 14.0182 122.874 15.0812 123.644 15.882C124.416 16.6852 125.329 17.0856 126.39 17.0856C127.45 17.0856 128.363 16.6923 129.135 15.9058V15.9034Z"
        fill="currentColor"
      />
      <path
        d="M136.18 17.5146C135.013 16.261 134.429 14.657 134.429 12.7003C134.429 10.7436 135.008 9.15631 136.169 7.93367C137.329 6.71102 138.773 6.09852 140.504 6.09852C141.517 6.09852 142.408 6.32968 143.18 6.78728C143.952 7.24726 144.548 7.85026 144.968 8.59862V1.89197H147.654V19.1829H144.968V16.7758C144.532 17.5694 143.931 18.2057 143.168 18.68C142.406 19.1543 141.517 19.3926 140.504 19.3926C138.79 19.3926 137.351 18.7658 136.18 17.5122V17.5146ZM143.809 15.9035C144.582 15.117 144.965 14.0612 144.965 12.7384C144.965 11.4157 144.579 10.3623 143.809 9.58532C143.037 8.80597 142.122 8.41749 141.064 8.41749C140.006 8.41749 139.09 8.7988 138.318 9.56146C137.546 10.3241 137.162 11.368 137.162 12.6931C137.162 14.0183 137.548 15.0812 138.318 15.882C139.09 16.6852 140.003 17.0856 141.064 17.0856C142.124 17.0856 143.037 16.6923 143.809 15.9058V15.9035Z"
        fill="currentColor"
      />
      <path
        d="M155.411 19.3951C153.543 19.3951 152.022 18.7873 150.854 17.5718C149.686 16.3564 149.103 14.7453 149.103 12.7361C149.103 10.727 149.694 9.11825 150.878 7.91229C152.063 6.70634 153.593 6.10095 155.468 6.10095C157.344 6.10095 158.867 6.68249 160.037 7.84078C161.205 9.00146 161.789 10.5173 161.789 12.3858C161.789 12.8386 161.758 13.2581 161.696 13.6465H151.836C151.898 14.7214 152.26 15.5746 152.923 16.2062C153.586 16.8378 154.415 17.1524 155.411 17.1524C156.86 17.1524 157.88 16.5518 158.471 15.353H161.346C160.957 16.5375 160.247 17.5075 159.22 18.263C158.192 19.0185 156.922 19.3951 155.411 19.3951ZM157.959 9.28983C157.273 8.65825 156.441 8.34366 155.459 8.34366C154.477 8.34366 153.655 8.65825 152.994 9.28983C152.332 9.92141 151.955 10.7651 151.86 11.8257H159.01C158.993 10.7675 158.643 9.92141 157.959 9.28983Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default Icon;
